import React from 'react';
import Header from './header';
import Footer from './footer';
import { Outlet } from 'react-router-dom';
import Sidebar from './sidebar';
import { useSiteState } from '../../providers/state-provider';

const Layout = () => {

    const { menuOpen } = useSiteState();

    return (
        <>
            <Header/>
            <Outlet/>
            <Footer/>
            <div id="anywhere-home" className={menuOpen ? "bgshow" : ""}></div>
            <Sidebar/>
        </>
    );
};

export default Layout;