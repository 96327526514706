import React, { useState } from 'react';

const questions = [
    {
        question: "What reports can Reportable's AI generate?",
        answer: "Reportable currently covers most damage reports in the insurance industry, such as, Building Assessments, Roof, Make Safe and Specialist reports."
    },
    {
        question: "Does Reportable save all my reports?",
        answer: "Yes! You can come back to Reportable and download your reports at any time."
    },
    {
        question: "Do I pay for each downloaded report?",
        answer: "No, you only pay for the initial generation of the report. Downloading it again, in the future, will not cost."
    }
]

const FAQs = () => {

    const [active, setActive] = useState(0);

    return (
        <div className="rts-faq-area rts-section-gap">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="title-conter-area">
                            <span className="pre-title-bg">FAQs</span>
                            <h2 className="title">
                                Questions About our Reportable? <br/>
                                We have Answers!
                            </h2>
                            <p className="disc">
                                Have any other questions? Feel free to get in touch.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row mt--60">
                    <div className="col-lg-12">
                        <div className="accordion-area-one">
                            <div className="accordion" id="faqAccordion">
                                {questions.map((q, i) => {
                                    return <div key={`faq_${i}`} className="accordion-item">
                                        <h2 className="accordion-header" id={`faqhead${i}`}>
                                            <button 
                                                className="accordion-button" 
                                                type="button" 
                                                data-bs-toggle="collapse" 
                                                data-bs-target={`#faq${i}`}
                                                aria-expanded={active === i} 
                                                aria-controls={`faq${i}`}
                                                onClick={() => setActive(i)}
                                            >
                                                {q.question}
                                            </button>
                                        </h2>
                                        <div id={`faq${i}`} className={`accordion-collapse collapse ${active === i ? "show" : ''}`} aria-labelledby={`faqhead${i}`} data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                {q.answer}
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FAQs;