import React, { useCallback, useState } from 'react';
import { siteConfig } from '../site-config';

import star from '../assets/images/banner/star.svg';
import brand1 from '../assets/images/banner/brand/01.png';
import brand2 from '../assets/images/banner/brand/02.png';
import brand3 from '../assets/images/banner/brand/03.png';
import brand4 from '../assets/images/banner/brand/04.png';
import bannerImage from '../assets/images/banner/02.png';

const Star = () => {
    return <img src={star} alt="star" loading="lazy"/>
}

const Banner = () => {

    const [email, setEmail] = useState('');

    const register = useCallback(() => {
        window.open(`${siteConfig.appUrl}/register?email=${email}`, "_blank");
    }, [email])

    return (
        <div className="banner-two-area bg-deam pt--100 pt_md--70">
            <div className="container px-3">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="banner-two-inner-content pb--180 pb_md--180 pb_sm--100 pt--200 pt_md--100 pt_sm--20">
                            <span className="pre-title">Welcome to Reportable</span>
                            <h1 className="title">
                                Save hours with<br/>
                                AI-Generated <span>Reports.</span>
                            </h1>
                            <p className="disc">
                                Experience the power of Reportable: The reporting software that streamlines on-site and desktop assessments, saving your company valuable time and resources.
                            </p>
                            <div className="input-area">
                                <input 
                                    type="email" 
                                    className='br-1'
                                    placeholder="Enter your mail address" 
                                    required 
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                                <button type="button" onClick={register} className="btn-primary">Get Started <i className="fa-regular fa-arrow-right"></i></button>
                            </div>
                            {/* <div className="rating-area">
                                <div className="rating">
                                    <Star/>
                                    <Star/>
                                    <Star/>
                                    <Star/>
                                    <Star/>
                                </div>
                                <p className="disc">Reviewed by our industry</p>
                            </div>
                            <div className="brand-arae">
                                <img src={brand1} alt="brand" loading="lazy"/>
                                <img src={brand2} alt="brand" loading="lazy"/>
                                <img src={brand3} alt="brand" loading="lazy"/>
                                <img src={brand4} alt="brand" loading="lazy"/>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="banner-right-img">
                <img src={bannerImage} alt="Reportable App Screenshot"/>
            </div>
        </div>
    );
};

export default Banner;