import React from 'react';
import { useSiteState } from '../../providers/state-provider';
import { Link } from 'react-router-dom';

const Sidebar = () => {

    const { menuOpen, setMenuOpen } = useSiteState();

    return (
        <div id="side-bar" className={`side-bar header-two ${menuOpen ? "show" : ""}`}>
            <button className="close-icon-menu" onClick={() => setMenuOpen(false)}><i className="far fa-times"></i></button>
            <div className="mobile-menu-main">
                <nav className="nav-main mainmenu-nav mt--30">
                    <ul className="mainmenu metismenu" id="mobile-menu-active">
                        <li className="parent"><Link to="/" className="main">Home</Link></li>
                        <li className="parent"><Link className="main" to={{ pathname: "/", hash: 'how-it-works' }}>How It Works</Link></li>
                        <li className="parent"><Link className="main" to={{ pathname: "/", hash: 'features' }}>Features</Link></li>
                        <li className="parent"><Link className="main" to={{ pathname: "/", hash: 'pricing' }}>Pricing</Link></li>
                        <li className="parent"><Link className="main" to="/contact">Contact</Link></li>
                    </ul>
                </nav>

                {/* <div className="rts-social-style-one pl--20 mt--100">
                    <ul>
                        <li>
                            <a href="#">
                                <i className="fa-brands fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i className="fa-brands fa-twitter"></i>
                            </a>
                        </li>
                    </ul>
                </div> */}
            </div>
        </div>
    );
};

export default Sidebar;