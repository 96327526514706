import React from 'react';
import Banner from '../components/banner';
import HowItWorks from '../components/how-it-works';
import Features from '../components/features';
import Reviews from '../components/reviews';
import Pricing from '../components/pricing';
import FAQs from '../components/faq';

const Home = () => {
    return (
        <>
        <Banner/>
        <HowItWorks/>
        <Features/>
        {/* <Reviews/> */}
        <Pricing/>
        <FAQs/>
        </>
    );
};

export default Home;