import React, { createContext, useContext, useState } from 'react';

const StateContext = createContext();

export const useSiteState = () => {
    return useContext(StateContext);
}

const StateProvider = ({ children }) => {

    const [menuOpen, setMenuOpen] = useState(false);
    
    return (
        <StateContext.Provider value ={{
            menuOpen,
            setMenuOpen
        }}>
            {children}
        </StateContext.Provider>
    );
};

export default StateProvider;