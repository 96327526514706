import React from 'react';
import logo from '../../assets/images/logo.png';

const Footer = () => {
    return (
        <div className="rts-footer-area footer-two footer-mid bg-footer">
            <div className="container  ptb--100">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="footer-one-main-wrapper">
                            <div className="footer-singl-wized left-logo">
                                <div className="head">
                                    <a href="#">
                                        <img src={logo} height={111} width={122} alt="Reportable logo"/>
                                    </a>
                                </div>
                                <div className="body">
                                    <p className="dsic mb--25">
                                        Reportable automates the creation of content in your damage reports with simple prompts from you.
                                    </p>
                                    <div className="social-area-one">
                                        {/* <ul>
                                            <li><a href="#"><i className="fa-brands fa-facebook-f"></i></a></li>
                                            <li><a href="#"><i className="fa-brands fa-twitter"></i></a></li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                            <div className="footer-singl-wized">
                                <div className="head">
                                    <h6 className="title">Reportable</h6>
                                </div>
                                <div className="body">
                                    <ul className="menu">
                                        <li><a href="/">Home</a></li>
                                        <li><a href="#how-it-works">How It Works</a></li>
                                        <li><a href="#features">Features</a></li>
                                        <li><a href="/contact">Contact Us</a></li>
                                    </ul>
                                </div>
                            </div>
                            {/* <div className="footer-singl-wized">
                                <div className="head">
                                    <h6 className="title">Use Cases</h6>
                                </div>
                                <div className="body">
                                    <ul className="menu">
                                        <li><a href="#">For Teams</a></li>
                                        <li><a href="#">For Email Marketers</a></li>
                                        <li><a href="#">For Blog Writers</a></li>
                                        <li><a href="#">For social managers</a></li>
                                    </ul>
                                </div>
                            </div> */}
                            <div className="footer-singl-wized input-area">
                                <div className="head">
                                    <h6 className="title">Terms & Privacy</h6>
                                </div>
                                <div className="body">
                                    <ul className="menu">
                                        <li><a href="/terms">Terms & Conditions</a></li>
                                        <li><a href="/privacy">Privacy Policy</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="copy-right-two">
                            <p><a href="#">Reportable</a> {new Date().getFullYear()}. All Rights Reserved.</p>
                            <div className="menu">
                                <ul>
                                    <li><a href="/terms">Terms</a></li>
                                    <li><a href="/privacy">Privacy Policy</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;