import { Routes, Route } from "react-router-dom";
import Header from "./components/layout/header";
import Layout from "./components/layout";
import PageNotFound from "./screens/404";
import Home from "./screens/home";
import Privacy from "./screens/privacy";
import Contact from "./screens/contact";
import ScrollToAnchor from "./components/scroll-to";
import StateProvider from "./providers/state-provider";
import Terms from "./screens/terms";

function App() {
  return (
    <StateProvider>
      <Header/>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="terms" element={<Terms />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
      <ScrollToAnchor/>
    </StateProvider>
  );
}

export default App;